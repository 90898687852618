import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SEO } from 'components';
import { graphql, Link } from 'gatsby';
import PrevArrow from 'images/shared/PrevArrow';

const OnboardingEmails = () => {
  return (
    <>
      <SEO pageSpecificTitle="Email Setup" />
      <Wrapper>
        <InnerWrapper>
          <BackButton to="/admin/onboarding/">
            <PrevArrow width="1.688rem" />
          </BackButton>
          <h1>
            Onboarding <span>- Emails</span>
          </h1>
          <Container>
            <h2>How to setup your new email</h2>
            <p>
              Once an email account has been created for you on Blacknight, you can go about setting
              it up on your work computer / laptop. The instructions vary depending on which email
              hosting service you intend to use, so we have outlined a few below.
            </p>
            <h4>Mac / Macbook</h4>
            <p>
              1. Open Up Mac Mail and browse to the following: <br />
              <small>Mail &#x3e; Add Account &#x3e; Other Mail Account</small>
            </p>
            <p>
              2. Provide the following settings:
              <br />
              <small> - Name: What you would like others to see when you send them an email</small>
              <br />
              <small> - Email address: The email address you are adding to Mac Mail</small>
              <br />
              <small>
                {' '}
                - Password: The password for this specific email address you are adding
              </small>
            </p>
            <p>
              3. Click Next and the provide the server information as requested:
              <br />
              <small>- Account Type: We would recommend POP</small>
              <br />
              <small>- Incoming & Outgoing Mail Server: both mail.blacknight.com</small>
            </p>
            <p>
              4. If you receive an error regarding `Unable to verify account settings`, proceed
              regardless.
            </p>
            <p>
              5. Browse to the following:
              <br />
              <small>
                Mail &#x3e; Preferences &#x3e; Accounts &#x3e; Select email account &#x3e; Server
                Settings
              </small>
            </p>
            <p>
              6. Ensure the following settings are applied:
              <br />
              <small>
                - Disable `Automatically manage connection settings` for both Incoming and Outgoing
              </small>
              <br />
              <small>- Host name: mail.blacknight.com for all host name fields</small>
              <br />
              <small>
                - Type the full email address for the username, and put in the password. Fill these
                out for both incoming and outgoing.
              </small>
              <br />
              <small>- Authentication: Password for both incoming and outgoing</small>
              <br />
              <small>- Use TLS/SSL must be enabled for both incoming and outgoing</small>
            </p>
            <br />
            <p>
              7. Finally, browse to the following and ensure these settings are applied:
              <br />
              <small>
                {' '}
                Mail &#x3e; Preferences &#x3e; Accounts &#x3e; Outgoing Mail &#x3e; Account: Edit
                SMTP Server List
              </small>
              <br />
              <small>
                - Ensure `Automatically manage connection settings` is disabled in here also
              </small>
              <br />
              <small>- Insert the same username, password and host name as above</small>
              <br />
              <small>- Port number for the outgoing server should be 587.</small>
            </p>
            <h4>Outlook / 365</h4>
            <p>1. Open Microsoft Outlook - Office 365</p>
            <p>
              2. Select FILE, then select "+ Add Account" button, located under Account Information
            </p>
            <p>3. Enter your email address in the dialog and then select "Advanced options"</p>
            <p>4. Select "Let me set up my account manually" and then select "Connect"</p>
            <p>5. Select IMAP in the following dialog</p>
            <p>
              6. Provide the following settings:
              <br />
              <small>Incoming Mail Server: mail.blacknight.com</small>
              <br />
              <small>Port: 143</small>
              <br />
              <small>Encryption method: None</small>
              <br />
              <small>Require logon using Secure Password Authentication (SPA): unchecked</small>
              <br />
              <small>Outgoing Mail Server: mail.blacknight.com</small>
              <br />
              <small>Port: 587</small>
              <br />
              <small>Encryption method: None</small>
              <br />
              <small>Require logon using Secure Password Authentication (SPA): unchecked</small>
              <br />
              <small>Then select "Next"</small>
            </p>
            <p>7. On the next screen, enter your email password, exactly how it was provided to you and click "Connect". Passwords are case-sensitive, and cannot include a "space" character as a prefix or suffix.</p>
            <p>8. Congratulations! You have successfully added your email account. Select "Done".</p>
          </Container>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const BackButton = styled(Link)`
  align-items: center;
  border-radius: 0.125rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-top: -0.75em;
  margin-bottom: 2rem;
  transition: 150ms ease-in-out;
  width: 3rem;

  &:hover {
    background-color: #000;
  }

  &:not(:hover) {
    background-color: var(--ax-gold);
  }

  &:active {
    background-color: #c1beac;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  h2 {
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 1em;
  }
  h3 {
    font-size: 2.5rem;
    font-weight: 300;
    color: var(--ax-gold);
    margin-bottom: 0.5em;
    margin-top: 1em;
  }
  h4 {
    font-size: 1.5rem;
    font-weight: 800;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  a {
    text-decoration: underline;
    color: var(--ax-gold);
  }

  p {
    font-size: 1.25rem;
    letter-spacing: 0.001em;
    line-height: 1.6em;
    margin-bottom: 1em;
    small {
      font-size: 0.925rem;
      margin-left: 1.25rem;
    }
  }
`;

const InnerWrapper = styled.div`
  grid-column: 2 / 3;
  max-width: 62.5rem !important;
  width: 100%;

  @media screen and (min-width: 48rem) {
    margin-top: -4.825rem;
  }

  @media screen and (min-width: 68.75rem) {
    margin: -3.5rem 0 9.1rem;
  }
`;

const Wrapper = styled.section`
  padding-top: 7.1rem;
  position: relative;
  min-height: auto;

  h1 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 0.01em;
    line-height: 1.208em;
    text-transform: capitalize;
    margin-bottom: 0.5em;
    span {
      font-size: 0.5em;
      color: var(--ax-gold);
      font-weight: 300;
    }
  }

  @media screen and (min-width: 48rem) {
    padding-top: 11.9rem;
    min-height: auto;
    h1 {
      font-size: 6rem;
      font-weight: 900;
      line-height: 1.219em;
    }
  }
`;

export default OnboardingEmails;
